<template>
  <div id="standardDetail" v-wechat-title='title'>
    <CommonBread></CommonBread>
    <div class="content">
      <h3 class="clearfix">
        {{ detail.standardNumber }}
        <span :class="[ 'grey pull-right', {
          'green': detail.standardState == 'ST',
          'red': detail.standardState == 'WT',
          'orange': detail.standardState == 'NST'
        }]">{{ detail.standardStateName }}</span>
      </h3>
      <h4>{{ detail.chineseName }}</h4>
      <p>{{ detail.englishName }}</p>
      <div v-if="detail.standardText" class="button">
        <a-button type="primary" @click="pdfShow"><svg-icon icon-class="reading"></svg-icon>在线阅读</a-button>
      </div>
      <div v-else class="no-button">
        由于版权原因，本平台暂不提供该标准阅读，如需查阅或获取全文文本请 <a-button type="text" @click="visible = true">联系我们</a-button> 。
      </div>
      <a-table 
        :columns="columns" 
        :show-header="false" 
        :data="data" 
        :hoverable="false"
        :pagination="false"
        :bordered="{cell:true}">
        <template #key="{ record }">
          {{ detail[record.key] || '-' }}
        </template>
      </a-table>
      <div v-if="detail.standardLabels">
        <h4>本标准收录在：</h4>
        <div>
          <a 
            v-for="item in detail.standardLabels" 
            :href="`/labelDetail/${item}`"
            target="_blank"
            :key="item"><svg-icon icon-class="hashtag"></svg-icon>{{ item }}</a>
        </div>
      </div>
    </div>
    <RelationModal :visible="visible" @close="visible = false"></RelationModal>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread.vue'
import RelationModal from './components/RelationModal.vue'
import utils from '@/assets/js/utils'
export default {
  components: {
    CommonBread,
    RelationModal
  },
  data() {
    return {
      visible: false,
      detail: {},
      columns: [
        {
          title: '',
          dataIndex: 'title',
          width: 100
        },
        {
          title: '',
          slotName: 'key'
        }
      ],
      data: [
        { title: '发布日期', key: 'releaseDate' },
        { title: '实施日期', key: 'implementDate' },
        { title: '标准组织', key: 'organization' },
        { title: 'ICS 分类', key: 'icsRedundancy' },
        { title: 'CCS 分类', key: 'ccsRedundancy' },
      ],
      title: '广东省碳达峰碳中和标准化技术信息公共服务平台'
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.StandardDetail({
        standardNumber: this.$route.query.id
      }, 3).then(res => {
        if(res.data.code == 200) {
          res.data.data.organization = res.data.data.organizationCode + ' ' + res.data.data.organizationName
          res.data.data.releaseDate = utils.formatDate(res.data.data.releaseDate)
          res.data.data.implementDate = utils.formatDate(res.data.data.implementDate)
          res.data.data.abolishDate =  res.data.data.abolishDate ? utils.formatDate(res.data.data.abolishDate) : ''
          if(res.data.data.abolishDate) {
            this.data.splice(2, 0, { title: '废止日期', key: 'abolishDate' })
          }
          this.detail = res.data.data
          this.title = `${res.data.data.standardNumber} ${res.data.data.chineseName} - 广东省碳达峰碳中和标准化技术信息公共服务平台`
        }else {
          this.$router.replace('/404')
        }
      })
    },
    pdfShow() {
      if(!this.$store.state.loginIn) return this.$store.commit('open_type', 1)
      window.open(`/showPdf?filePath=${this.detail.standardText.url}`)
    }
  }
}
</script>

<style lang='scss'>
  #standardDetail {
    width: 1200px;
    margin: 0 auto;

    .content {
      padding: 0 10px;

      & > h3 {
        font-size: 32px;
        color: #05a081;
        line-height: 46px;
        margin-bottom: 10px;

        & > span {
          width: 72px;
          height: 24px;
          border-radius: 2px;
          color: #fff;
          font-size: 14px;
          display: inline-block;
          line-height: 24px;
          text-align: center;
          margin-top: 13px;
        }
      }

      & > h4 {
        font-size: 18px;
        color: #303133;
        line-height: 26px;
        margin-bottom: 10px;
      }

      & > p {
        font-size: 16px;
        color: #606266;
        line-height: 22px;
        margin-bottom: 30px;
      }

      .button {
        padding: 10px 0;
        margin-bottom: 20px;

        .arco-btn {
          width: 121px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }

      .arco-table-cell {
        color: #606266;
        font-size: 14px;  
      }

      .arco-table {
        margin-bottom: 20px;
      }

      & > div {
        & > h4 {
          padding: 9px 0;
          font-size: 16px;
          line-height: 20px;
        }

        & > div {
          padding: 10px 0 16px;
          display: flex;

          & > a {
            padding: 5px 13px;
            background: #eaf4ff;
            border-radius: 14px;
            margin-right: 10px;
            font-size: 12px;
            color: #0299ff;
            line-height: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: none;

            &:hover {
              background: #DDEDFF;
            }

            .svg-icon {
              width: 16px;
              height: 16px;
              margin-right: 3px;
            }
          }
        }
      }
    }
    .no-button {
      height: 60px;
      background: #f5f6f7;
      font-size: 16px;
      color: #666666;
      padding: 19px 20px;
      margin-bottom: 20px;

      .arco-btn-size-medium {
        height: 20px;
        padding: 0 5px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
</style>