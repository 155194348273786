<template>
  <div class="common_bread">
    所在位置:<a-breadcrumb>
      <a-breadcrumb-item><router-link :to="$route.meta.url">{{ typeof $route.meta.title == 'string' ? $route.meta.title : $route.meta.title[0] }}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ typeof $route.meta.title == 'object' && $route.meta.title[1] || '详情' }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
  .common_bread {
    width: 1200px;
    margin: 0 auto;
    padding: 3px 10px 13px;
    color: #909399;
    line-height: 20px;

    .arco-breadcrumb {
      margin-left: 10px;

      .arco-breadcrumb-item-separator {
        line-height: 20px;
      }

      .arco-breadcrumb-item {
        color: #606266;
        font-size: 14px;
        line-height: 20px;

        & > a {
          font-weight: 600;
          color: #303133;
        }
      }
    }
  }
</style>