<template>
  <a-modal 
    :visible="visible" 
    :unmount-on-close="true"
    :footer="false"
    modal-class="relation_modal"
    title=""
    :width="520"
    @cancel="$emit('close')">
    <template #title> </template>
    <div class="top clearfix">
      <div class="pull-left">
        <h1>联系我们</h1>
        <p>Contact Us</p>
      </div>
      <img class="pull-right" src="../../../assets/img/contact.png" />
    </div>
    <a-form :model="form" layout="vertical">
      <a-form-item label="电子邮箱：">
        <a-input-search readonly :style="{width:'468px'}" v-model="form.value" search-button  :data-clipboard-text="form.value" @search="searchClick" >
          <template #button-default>
            点击复制
          </template>
        </a-input-search>
      </a-form-item>
    </a-form>
    <div class="tip">
      <h4>该标准的版权归属于标准发布方或出版方，根据发布方或出版方的版权要求，本平台只收录本文件相关题录信息，暂不提供全文文本。</h4>
      <p>如需查阅或获取标准全文文本， 请访问标准发布方或出版方的官方网站或向上方电子邮箱致信给我们。</p>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: ['visible'],
  data() {
    return { 
      form: {
        value: 'ITMarketing@gdis.org.cn'
      }
    }
  },
  methods: {
    searchClick () {
      
      var e = document.getElementsByClassName("arco-input")[0];//对象是content
      e.select(); //选择对象
      document.execCommand("Copy"); //执行浏览器复制命令
      this.$message.success('复制成功')
    }
  }
}
</script>

<style lang='scss'>
.relation_modal {
  text-align: left;

  .arco-modal-header {
    height: 48px;
  }

  .arco-modal-body {
    padding:  0 16px 50px;

    .top {
      padding: 18px 10px 10px;
      
      .pull-left {
        h1 {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 5px;
          margin-top: 7px;
        }

        p {
          font-size: 14px;
          color: #4e5969;
        }
      }

      img {
        width: 100px;
        height: 70px
      }
    }

    .arco-form {
      margin-top: 20px;
      padding: 0 10px;

      .arco-input-wrapper .arco-input {
        color: #303133;
        font-weight: 600;
      }
    }

    .tip {
      background: #f2f2f2;
      padding: 20px;
      margin: 0 10px;

      & > h4 {
        margin-bottom: 15px; 
        font-size: 14px;
        color: #333333;
        line-height: 24px;
      }

      & > p {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }
    }
  }
}


</style>